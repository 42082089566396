import { NISTCSF2ResponseSchemaVersion } from "../types/risk-and-gap";
import { convertSnakeToCapitalized } from "./snake-to-capital";

export const getFieldTitle = (key: string, version?: NISTCSF2ResponseSchemaVersion) => {
  switch (key){
    case 'sources':
      return (version === 'v2') ? 'Other References' : 'References';
    default:
      return convertSnakeToCapitalized(key);
  }
}