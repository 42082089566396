import {
  AgentSessionStep,
  DoraAgentSession,
  QaAgentSession,
  RiskAgentSession,
  SOC2GapAgentSession,
} from '@/modules/sessions/types';
import { AiResponseType } from '@/shared/types/user';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import {
  QuestionReviewReponse,
  QuestionnaireFileData,
  QuestionnaireFiller,
  QuestionnaireMapping,
} from './questionnaire';
import {
  DoraReviewResponse,
  RiskReviewResponse,
  SOC2GapReviewResponse,
} from './risk-and-gap';

export const dataNotFound = 'Data-Not-Found';

export interface UploadDialogState {
  isOpen: boolean;
  type?: AGENT_TYPES;
  subtype?: AgentSubTypes;
}

export enum AGENT_TYPES {
  RISK_ASSESSMENT = 'risk_assessment',
  GAP_ASSESSMENT = 'gap_assessment',
  QUESTIONNAIRE = 'questionnaire',
}

export enum GapAssessmentTypes {
  DORA = 'dora',
  SOC2 = 'soc-2',
}

export enum RiskAssessmentTypes {
  NIST_CSF_2 = 'nist-csf-2',
}

export enum QuestionnaireTypes {
  QA = 'qa',
}

export type AgentSubTypes =
  | RiskAssessmentTypes
  | GapAssessmentTypes
  | QuestionnaireTypes;

export type AgentSpecificSubTypes<T extends AGENT_TYPES> =
  T extends AGENT_TYPES.RISK_ASSESSMENT
    ? RiskAssessmentTypes
    : T extends AGENT_TYPES.GAP_ASSESSMENT
      ? GapAssessmentTypes
      : T extends AGENT_TYPES.QUESTIONNAIRE
        ? QuestionnaireTypes
        : never;

export type AgentSpecificMainData<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2
    ? RiskAgentMainData
    : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraMainData
      : S extends GapAssessmentTypes.SOC2
        ? SOC2GapMainData
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QuestionnaireAgentMainData
        : never
      : never;

export type AgentSpecificSession<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2
    ? RiskAgentSession
    : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraAgentSession
      : S extends GapAssessmentTypes.SOC2
        ? SOC2GapAgentSession
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QaAgentSession
        : never
      : never;

export type AgentData<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = {
  agentType: T;
  subType: S;
  sessionData: AgentSpecificSession<T, S>;
  stepData: AgentSessionStep[];
  mainData: AgentSpecificMainData<T, S>;
  responseQuality: AiResponseType;
};

export interface AgentState {
  agentDataMap: Map<
    string,
    AgentData<AGENT_TYPES, AgentSpecificSubTypes<AGENT_TYPES>>
  >;
  agentLogBaseData: any;
}

export type AgentSpecificTableRowData<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2
    ? RiskReviewResponseTableRow
    : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraGapReviewResponseTableRow
      : S extends GapAssessmentTypes.SOC2
        ? Soc2GapReviewResponseTableRow
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QuestionnaireTableRow
        : never
      : never;

export interface AgentSourceFile {
  url: string;
  fileType?: string;
  fileName: string;
}

export type AgentMainDataBase<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = {
  sourceFilesUrls: Map<string, AgentSourceFile>;
  selectedId?: string;
  finalFileUrl?: string;
  staleUrl?: string;
  reviewResponseIds?: string[];
  reviewResponseData?: Map<
    string,
    ReviewResponseData<keyof AgentSpecificReviewResponseType<T, S>>[]
  >;
  filters?: FilterOption[];
  tableRows?: AgentSpecificTableRowData<T, S>[];
  approvedIds: string[];
  editedIds: string[];
  searchTerm?: string;
  searchFor?: (keyof AgentSpecificTableRowData<T, S>)[];
};

export type ReviewResponseItemStatus = 'approved' | 'edited' | 'none';

export interface RiskReviewResponseTableRow {
  id: string;
  tag: string | null;
  riskString: string | null;
  riskValue: string | number;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export interface Soc2GapReviewResponseTableRow {
  id: string;
  tag: string | null;
  control: string | null;
  compliant: ComplianceStatus;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export interface DoraGapReviewResponseTableRow {
  id: string;
  tag: string | null;
  requirement: string | null;
  compliant: ComplianceStatus;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export interface QuestionnaireTableRow {
  id: string;
  question: string | null;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export type ReviewResponseTableRow =
  | RiskReviewResponseTableRow
  | Soc2GapReviewResponseTableRow
  | QuestionnaireTableRow
  | DoraGapReviewResponseTableRow;

export type ReviewResponseData<T> = {
  type: ReviewResponseType;
  value: any;
  key: T;
  title: string;
  placeholder?: string;
  isCollapsible?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  shouldRender: boolean;
};

export interface AgentFilter<T extends string | number> {
  key: string;
  value: T[];
  operator: 'equals';
  isActive: boolean;
  type: string;
}

export interface AgentSearch<T> {
  searchTerm: string;
  searchFor?: T[];
}

export enum ReviewResponseType {
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  NUMBER = 'number',
  SOURCE = 'source',
  TEXT_DISPLAY = 'text_display',
  FUNCTION = 'function',
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
  COMPLIANCE_STATUS = 'compliance_status',
  COMBINED = 'combined',
  TEXT_WITH_SOURCE = 'text_with_source',
  LIST_OF_TEXT_WITH_SOURCE = 'list_of_text_with_source',
  LIST_OF_COMBINED_WITH_SOURCE = 'list_of_combined_with_source',
}

export type RiskNistCsf2ReviewResponse = RiskReviewResponse;

export type SOC2GapReviewResponseType = SOC2GapReviewResponse;

export type DoraReviewResponseType = DoraReviewResponse;

export type QuestionnaireReviewResponse = Pick<
  QuestionReviewReponse,
  | 'comment'
  | 'answer'
  | 'justification'
  | 'sources'
  | 'confidence'
  | 'question_text'
  | 'question_id'
>;

export type AgentSpecificReviewResponseType<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2
    ? RiskNistCsf2ReviewResponse
    : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraReviewResponse
      : S extends GapAssessmentTypes.SOC2
        ? SOC2GapReviewResponseType
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QuestionnaireReviewResponse
        : never
      : never;

export type ReviewResponse<T extends AGENT_TYPES> = {
  ids: string[];
  data: Map<
    string,
    AgentSpecificReviewResponseType<T, AgentSpecificSubTypes<T>>
  >;
};

export interface RiskAgentMainData
  extends AgentMainDataBase<
    AGENT_TYPES.RISK_ASSESSMENT,
    RiskAssessmentTypes.NIST_CSF_2
  > {}

export interface SOC2GapMainData
  extends AgentMainDataBase<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.SOC2
  > {}

export interface DoraMainData
  extends AgentMainDataBase<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.DORA
  > {}

export interface QuestionnaireAgentMainData
  extends AgentMainDataBase<AGENT_TYPES.QUESTIONNAIRE, QuestionnaireTypes.QA> {
  questionnaireFileData: QuestionnaireFileData;
  questionnaireMapping: QuestionnaireMapping;
  selectedSheet: string;
  questionnaireFiller: QuestionnaireFiller[];
}

export enum ConfidenceTypes {
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium',
  NA = 'N/A',
  DNF = 'Data-Not-Found',
}

export enum ComplianceStatus {
  FULLY_COMPLIANT = 'Fully-Compliant',
  PARTIALLY_COMPLIANT = 'Partially-Compliant',
  NON_COMPLIANT = 'Non-Compliant',
  DNF = 'Data-Not-Found',
}

export enum SOC2ComplianceStatusDeprecated {
  FULLY_COMPLIANT = 'Fully Compliant',
  PARTIALLY_COMPLIANT = 'Partially Compliant',
  NON_COMPLIANT = 'Non Compliant',
  DNF = 'Data Not Found',
}

export enum FilterOperators {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  IS_SET = 'IS_SET',
  IS_NOT_SET = 'IS_NOT_SET',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL_TO = 'LESS_THAN_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL_TO = 'GREATER_THAN_EQUAL_TO',
  BETWEEN = 'BETWEEN',
  NOT_BETWEEN = 'NOT_BETWEEN',
}

export interface FilterOptionCommon {
  key?: string;
  className?: string;
  label: string;
  id: string;
}

export interface FilterOptionWithValueAsString extends FilterOptionCommon {
  operator:
    | FilterOperators.EQUALS
    | FilterOperators.NOT_EQUALS
    | FilterOperators.CONTAINS
    | FilterOperators.NOT_CONTAINS
    | FilterOperators.STARTS_WITH
    | FilterOperators.ENDS_WITH;
  value: string;
}

export interface FilterOptionWithValueAsNumber extends FilterOptionCommon {
  operator:
    | FilterOperators.LESS_THAN
    | FilterOperators.LESS_THAN_EQUAL_TO
    | FilterOperators.GREATER_THAN
    | FilterOperators.GREATER_THAN_EQUAL_TO;
  value: number;
}

export interface FilterOptionWithValueAsBoolean extends FilterOptionCommon {
  operator: FilterOperators.IS_SET | FilterOperators.IS_NOT_SET;
  value: boolean;
}

export interface FilterOptionWithValueAsRange extends FilterOptionCommon {
  operator: FilterOperators.BETWEEN | FilterOperators.NOT_BETWEEN;
  value: { min: number; max: number };
}

export type FilterOption =
  | FilterOptionWithValueAsString
  | FilterOptionWithValueAsNumber
  | FilterOptionWithValueAsBoolean
  | FilterOptionWithValueAsRange;

export enum FilterSelectionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DROPDOWN = 'dropdown',
  DROPDOWN_MULTI = 'dropdown_multi',
  DROPDOWN_RANGE = 'dropdown_range',
}

export interface FilterCategory {
  category: string;
  type: FilterSelectionType;
  options: FilterOption[];
  key: string;
}

export enum ResponseModes {
  DUMMY = 'dummy',
  DEMO = 'demo',
  PARTIAL = 'partial',
  FULL = 'full',
}

export interface CommonReviewSource {
  source_file_name: string;
  file_type: string;
  source_text?: string;
  file_name?: string;
  file_url?: string;
  source_number?: number;
  is_inline_source?: boolean;
}

export interface ExcelReviewSource extends CommonReviewSource {
  source_row_number?: number;
  row_number?: number;
  sheet_name?: string;
}

export interface JSONReviewSource extends CommonReviewSource {
  source_json_id?: string;
}

export interface PDFReviewSource extends CommonReviewSource {
  source_page_number?: number;
  source_highlight_area?: HighlightArea[];
  highlight_area?: HighlightArea[];
  page_number?: number;
}

export type ReviewSourceTypes =
  | CommonReviewSource
  | ExcelReviewSource
  | JSONReviewSource
  | PDFReviewSource;
