import { getZaniaSocket } from '@/infra/sockets';
import {
  NistCsf2RagCreateRequest,
  Soc2RagCreateRequest,
  DoraRagCreateRequest,
} from '@/modules/sessions/types';
import { post } from '@/infra/rest';

export const emitRagCreate = async (
  data: Soc2RagCreateRequest | NistCsf2RagCreateRequest | DoraRagCreateRequest,
  callback?: (...args: any[]) => void
) => {
  const RAG_IDENTIFIER = 'task:rag:create';
  const socket = await getZaniaSocket();

  socket.emit(RAG_IDENTIFIER, data, callback);
};

interface RAGDataRequest {
  task: string;
  output_format: string;
  json_url: string;
  response_quality: string;
}

interface RAGResponse {
  success: boolean;
  message: string;
  data: {
    file_url: string;
    output_format: string;
  };
}

export const getRAGExcelFromJSON = (data: RAGDataRequest) => {
  return post<RAGDataRequest, RAGResponse>({
    url: '/v0/rag/converter',
    data,
  });
};
