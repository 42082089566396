import { ROUTES } from '@/shared/constants/routes';
import { importLazy } from '@/shared/utils/import-lazy';
import { LoaderFunctionArgs, redirect, RouteObject } from 'react-router-dom';
import { getAgentData } from '../states';
import { getAgentSession } from '@/modules/sessions/requests';
import {
  handleContinueDoraGapAssessment,
  handleContinueQuestionnaire,
  handleContinueRiskAssessment,
  handleContinueSoc2GapAssessment,
} from '@/modules/sessions/use-cases/agent-session.use-case';
import { AgentSessionType } from '@/modules/sessions/types';
import { addNotification } from '@/shared/states/notification';

const agentLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (!id) {
    addNotification({
      message: 'Agent not found',
      type: 'error',
    });
    return redirect(`/${ROUTES.AGENT}`);
  }
  const agent = getAgentData(id);
  if (agent) {
    return true;
  } else {
    try {
      const agentFromApi = await getAgentSession(id);

      if (!agentFromApi || !agentFromApi.data) {
        addNotification({
          message: 'Agent not found',
          type: 'error',
        });
        return redirect(`/${ROUTES.AGENT}`);
      }
      const agentType = agentFromApi.data.session.type;
      if (agentType === AgentSessionType.RISK_ASSESSMENT) {
        await handleContinueRiskAssessment({
          session: agentFromApi.data.session,
          steps: agentFromApi.data.steps,
        });
      } else if (agentType === AgentSessionType.QA_FILLER) {
        await handleContinueQuestionnaire({
          session: agentFromApi.data.session,
          steps: agentFromApi.data.steps,
        });
      } else if (agentType === AgentSessionType.SOC2_GAP) {
        await handleContinueSoc2GapAssessment({
          session: agentFromApi.data.session,
          steps: agentFromApi.data.steps,
        });
      } else if (agentType === AgentSessionType.DORA_GAP) {
        await handleContinueDoraGapAssessment({
          session: agentFromApi.data.session,
          steps: agentFromApi.data.steps,
        });
      }
    } catch (error) {
      addNotification({
        message: 'Error while continuing risk assessment',
        type: 'error',
      });
      return redirect(`/${ROUTES.AGENT}`);
    }
    return true;
  }
};

export const AgentRoutes: RouteObject[] = [
  {
    path: ROUTES.AGENT,
    children: [
      {
        path: ':id',
        lazy: importLazy(() => import('@/modules/agent/components/layout')),
        loader: agentLoader,
      },
      {
        index: true,
        lazy: importLazy(() => import('@/modules/agent/components')),
      },
    ],
  },
];
