import { AiResponseType } from '@/shared/types/user';
import { ResponseModes } from '../types';

export const getResponseTypes = (seletedAiResponse: AiResponseType) => {
  switch (seletedAiResponse) {
    case AiResponseType.LITE:
      return {
        response_mode: ResponseModes.DUMMY,
        response_quality: AiResponseType.LITE,
      };
    case AiResponseType.SPEED:
      return {
        response_mode: ResponseModes.PARTIAL,
        response_quality: AiResponseType.LITE,
      };
    case AiResponseType.ACCURACY:
      return {
        response_mode: ResponseModes.FULL,
        response_quality: AiResponseType.ACCURACY,
      };
    default:
      return {
        response_mode: ResponseModes.DUMMY,
        response_quality: AiResponseType.LITE,
      };
  }
};
